import {
    TechRadarApi,
    TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

import techRadarData from './tech-radar.json';

export class TechRadarClient implements TechRadarApi {
    async load(): Promise<TechRadarLoaderResponse> {

        const data = techRadarData;

        return {
            ...data,
            entries: data.entries.map(entry => ({
                ...entry,
                timeline: entry.timeline.map(timeline => ({
                    ...timeline,
                    date: new Date(timeline.date),
                })),
            })),
        };
    }
}
